<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">The rate law for a hypothetical reaction is given below:</p>
      <p class="mb-5 pl-8">
        <stemble-latex content="$\text{rate}=\text{k[A]}^x$" />
      </p>

      <p class="mb-5">
        Determine the value of
        <stemble-latex content="x" />
        for each of the scenarios described below.
      </p>

      <p class="mb-n3">
        a) If the rate doubles when
        <chemical-latex content="[A]" />
        is doubled
      </p>

      <v-radio-group v-model="inputs.order1" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p class="mb-n3 mt-1">
        b) If the rate quadruples when
        <chemical-latex content="[A]" />
        is doubled
      </p>

      <v-radio-group v-model="inputs.order2" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p class="mb-n3 mt-1">
        c) If the rate is unchanged when
        <chemical-latex content="[A]" />
        is doubled
      </p>

      <v-radio-group v-model="inputs.order3" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question88',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        order1: null,
        order2: null,
        order3: null,
      },
      options: [
        {condition: 'Zeroth Order', value: '0'},
        {condition: 'First Order', value: '1'},
        {condition: 'Second Order', value: '2'},
        {condition: 'Third Order', value: '3'},
      ],
    };
  },
};
</script>
